import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchForm from "./SearchForm";
import "../../Css/Header.css";
import { RiPencilFill } from "react-icons/ri";
import { FaUserEdit } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { BsBookmarks } from "react-icons/bs";
import SkeletonElement from "../Skeletons/SkeletonElement";
import { AuthContext } from "../../Context/AuthContext";

const Header = () => {
  const bool = localStorage.getItem("authToken") ? true : false;
  const [auth, setAuth] = useState(bool);
  const { activeUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setAuth(bool);

    setTimeout(() => {
      setLoading(false);
    }, 1600);
  }, [setLoading, bool]);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/login");
  };

  return (
    <header>
      <div className="averager">
        <Link to="/" className="logo">
          <h5>HTB - Walkthrough</h5>
        </Link>
        <a
          className="portfolio"
          href="https://haimazu.onrender.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          My Portfolio
        </a>
        <SearchForm />
        <div className="header_options">
          {auth ? (
            <div className="auth_options">
              {activeUser.role === "admin" ? (
                <Link className="addStory-link" to="/addstory">
                  <RiPencilFill /> Add Story{" "}
                </Link>
              ) : (
                ""
              )}

              <Link to="/readList" className="readList-link">
                <BsBookmarks />
                <span id="readListLength">{activeUser.readListLength}</span>
              </Link>
              <div className="header-profile-wrapper ">
                {loading ? (
                  <SkeletonElement type="minsize-avatar" />
                ) : (
                  <img src={activeUser.photo.url} alt={activeUser.username} />
                )}

                <div className="sub-profile-wrap">
                  <Link className="profile-link" to="/profile">
                    {" "}
                    <FaUserEdit /> Profile{" "}
                  </Link>

                  <button className="logout-btn" onClick={handleLogout}>
                    {" "}
                    <BiLogOut /> Logout
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="noAuth_options">
              <Link className="login-link" to="/login">
                {" "}
                Login{" "}
              </Link>

              <Link className="register-link" to="/register">
                {" "}
                Register
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
