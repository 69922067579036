import React from "react";
import "../../Css/Pagination.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { TiMinus } from "react-icons/ti";

const Pagination = ({ page, pages, changePage }) => {
  const visiblePages = 3;
  const boundaryPages = 1; // Number of pages always visible at the beginning and end

  function numberRange(start, end) {
    return new Array(end - start + 1).fill().map((_, i) => start + i);
  }

  function getPageRange(currentPage, totalPages) {
    const halfRange = Math.floor(visiblePages / 2);

    if (totalPages <= visiblePages + 2 * boundaryPages) {
      // Show all pages if the total number is small
      return numberRange(1, totalPages);
    } else if (currentPage <= visiblePages) {
      // At the beginning of the pagination
      return [
        ...numberRange(1, visiblePages + boundaryPages),
        "ellipsis",
        totalPages,
      ];
    } else if (currentPage > totalPages - visiblePages) {
      // At the end of the pagination
      return [
        1,
        "ellipsis",
        ...numberRange(
          totalPages - visiblePages - boundaryPages + 1,
          totalPages
        ),
      ];
    } else {
      // In the middle of the pagination
      return [
        1,
        "ellipsis",
        ...numberRange(currentPage - halfRange, currentPage + halfRange),
        "ellipsis",
        totalPages,
      ];
    }
  }

  const paginationRange = getPageRange(page, pages);

  return (
    pages > 1 && (
      <div className="pagination">
        <button
          className="pagination__prev"
          onClick={() => changePage(Math.max(1, page - 1))}
          disabled={page === 1}
        >
          {page === 1 ? <TiMinus color="gray" /> : <FaChevronLeft />}
        </button>

        {paginationRange.map((num) => {
          if (num === "ellipsis") {
            return (
              <button
                key={num}
                className="pagination__ellipsis"
                onClick={() => changePage(page + visiblePages)}
              >
                ...
              </button>
            );
          }
          return (
            <button
              key={num}
              onClick={() => changePage(num)}
              disabled={page === num}
            >
              {num}
            </button>
          );
        })}

        <button
          className="pagination__next"
          onClick={() => changePage(Math.min(pages, page + 1))}
          disabled={page === pages}
        >
          {page === pages ? <TiMinus color="gray" /> : <FaChevronRight />}
        </button>
      </div>
    )
  );
};

export default Pagination;
