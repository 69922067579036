import React from "react";
import "../../Css/Loader.css";

const Loader = () => {
  return (
    <div className="center">
      <div className="spinner-border"></div>
      <h2>Loading...</h2>
    </div>
  );
};

export default Loader;
